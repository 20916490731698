/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TicketData } from '../models/ticket-data';
import { TicketCreate } from '../models/ticket-create';
import { TicketRestore } from '../models/ticket-restore';
import { Notifications } from '../models/notifications';
@Injectable({
  providedIn: 'root',
})
class TicketService extends __BaseService {
  static readonly ticketListListPath = '/ticket/list';
  static readonly ticketListCreatePath = '/ticket/list';
  static readonly ticketRestoreCreatePath = '/ticket/restore';
  static readonly ticketWidgetEditListPath = '/ticket/widget/edit';
  static readonly ticketReadPath = '/ticket/{id}';
  static readonly ticketUpdatePath = '/ticket/{id}';
  static readonly ticketPartialUpdatePath = '/ticket/{id}';
  static readonly ticketDeletePath = '/ticket/{id}';
  static readonly ticketCancelCreatePath = '/ticket/{id}/cancel';
  static readonly ticketNotificationsCreatePath = '/ticket/{id}/notifications';
  static readonly ticketPrintListPath = '/ticket/{id}/print';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TicketService.TicketListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket_type id
   *
   * - `ticket_code`: ticket code
   *
   * - `status`: ticket status (NEW - 0, BOOKED - 1, PAID - 2, CANCELLED - -1
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ticket ids separated by comma
   */
  ticketListListResponse(params: TicketService.TicketListListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<TicketData>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticketType != null) __params = __params.set('ticket_type', params.ticketType.toString());
    if (params.ticketCode != null) __params = __params.set('ticket_code', params.ticketCode.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<TicketData>}>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketListListParams` containing the following parameters:
   *
   * - `ticket_type`: ticket_type id
   *
   * - `ticket_code`: ticket code
   *
   * - `status`: ticket status (NEW - 0, BOOKED - 1, PAID - 2, CANCELLED - -1
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ticket ids separated by comma
   */
  ticketListList(params: TicketService.TicketListListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<TicketData>}> {
    return this.ticketListListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<TicketData>})
    );
  }

  /**
   * @param data undefined
   */
  ticketListCreateResponse(data: TicketCreate): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param data undefined
   */
  ticketListCreate(data: TicketCreate): __Observable<TicketData> {
    return this.ticketListCreateResponse(data).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param data undefined
   */
  ticketRestoreCreateResponse(data: TicketRestore): __Observable<__StrictHttpResponse<TicketRestore>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketRestore>;
      })
    );
  }
  /**
   * @param data undefined
   */
  ticketRestoreCreate(data: TicketRestore): __Observable<TicketRestore> {
    return this.ticketRestoreCreateResponse(data).pipe(
      __map(_r => _r.body as TicketRestore)
    );
  }

  /**
   * @param params The `TicketService.TicketWidgetEditListParams` containing the following parameters:
   *
   * - `ticket`: ticket id
   *
   * - `public_token`: ticket public token
   */
  ticketWidgetEditListResponse(params: TicketService.TicketWidgetEditListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticket != null) __params = __params.set('ticket', params.ticket.toString());
    if (params.publicToken != null) __params = __params.set('public_token', params.publicToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket/widget/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketWidgetEditListParams` containing the following parameters:
   *
   * - `ticket`: ticket id
   *
   * - `public_token`: ticket public token
   */
  ticketWidgetEditList(params: TicketService.TicketWidgetEditListParams): __Observable<null> {
    return this.ticketWidgetEditListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket.
   */
  ticketReadResponse(id: number): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket.
   */
  ticketRead(id: number): __Observable<TicketData> {
    return this.ticketReadResponse(id).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param params The `TicketService.TicketUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket.
   *
   * - `data`:
   */
  ticketUpdateResponse(params: TicketService.TicketUpdateParams): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ticket/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket.
   *
   * - `data`:
   */
  ticketUpdate(params: TicketService.TicketUpdateParams): __Observable<TicketData> {
    return this.ticketUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param params The `TicketService.TicketPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket.
   *
   * - `data`:
   */
  ticketPartialUpdateResponse(params: TicketService.TicketPartialUpdateParams): __Observable<__StrictHttpResponse<TicketData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/ticket/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TicketData>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this ticket.
   *
   * - `data`:
   */
  ticketPartialUpdate(params: TicketService.TicketPartialUpdateParams): __Observable<TicketData> {
    return this.ticketPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as TicketData)
    );
  }

  /**
   * @param id A unique integer value identifying this ticket.
   */
  ticketDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ticket/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this ticket.
   */
  ticketDelete(id: number): __Observable<null> {
    return this.ticketDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ticketCancelCreateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket/${encodeURIComponent(String(id))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ticketCancelCreate(id: string): __Observable<null> {
    return this.ticketCancelCreateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TicketService.TicketNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  ticketNotificationsCreateResponse(params: TicketService.TicketNotificationsCreateParams): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ticket/${encodeURIComponent(String(params.id))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  ticketNotificationsCreate(params: TicketService.TicketNotificationsCreateParams): __Observable<Notifications> {
    return this.ticketNotificationsCreateResponse(params).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * @param params The `TicketService.TicketPrintListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`: format of document html or pdf
   */
  ticketPrintListResponse(params: TicketService.TicketPrintListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.format != null) __params = __params.set('format', params.format.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ticket/${encodeURIComponent(String(params.id))}/print`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TicketService.TicketPrintListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`: format of document html or pdf
   */
  ticketPrintList(params: TicketService.TicketPrintListParams): __Observable<null> {
    return this.ticketPrintListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TicketService {

  /**
   * Parameters for ticketListList
   */
  export interface TicketListListParams {

    /**
     * ticket_type id
     */
    ticketType?: string;

    /**
     * ticket code
     */
    ticketCode?: string;

    /**
     * ticket status (NEW - 0, BOOKED - 1, PAID - 2, CANCELLED - -1
     */
    status?: number;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ticket ids separated by comma
     */
    ids?: string;
  }

  /**
   * Parameters for ticketWidgetEditList
   */
  export interface TicketWidgetEditListParams {

    /**
     * ticket id
     */
    ticket: string;

    /**
     * ticket public token
     */
    publicToken: string;
  }

  /**
   * Parameters for ticketUpdate
   */
  export interface TicketUpdateParams {

    /**
     * A unique integer value identifying this ticket.
     */
    id: number;
    data: TicketData;
  }

  /**
   * Parameters for ticketPartialUpdate
   */
  export interface TicketPartialUpdateParams {

    /**
     * A unique integer value identifying this ticket.
     */
    id: number;
    data: TicketData;
  }

  /**
   * Parameters for ticketNotificationsCreate
   */
  export interface TicketNotificationsCreateParams {
    id: string;
    data: Notifications;
  }

  /**
   * Parameters for ticketPrintList
   */
  export interface TicketPrintListParams {
    id: string;

    /**
     * format of document html or pdf
     */
    format?: string;
  }
}

export { TicketService }
