import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TicketWithFormModel } from '@pirexpo/shared/model';
import {
  ApplyPromocode,
  Invoice,
  Order,
  OrderAddTicket,
  SBPOrder,
  ShopWidget,
  TicketData,
  TicketRestore,
} from '@swagger/models';

export const OrderActions = createActionGroup({
  events: {
    'Apply Promocode': props<{
      payload: ApplyPromocode;
    }>(),
    'Apply Promocode Failure': props<{ error: unknown }>(),
    'Apply Promocode Success': props<{ order: Order }>(),

    'Create Invoice': props<{
      payload: Invoice;
    }>(),
    'Create Invoice Failure': props<{ error: unknown }>(),
    'Create Invoice Success': props<{ invoice: Invoice }>(),

    'Create Order': props<{ ticketTypeIds: Order['id'][] }>(),
    'Create Order Failure': props<{ error: unknown }>(),
    'Create Order Success': props<{ order: Order }>(),

    'Load Invoice': emptyProps(),
    'Load Invoice Failure': props<{ error: unknown }>(),
    'Load Invoice Success': props<{ invoice: Invoice }>(),

    'Load Order': emptyProps(),
    'Load Order Failure': props<{ error: unknown }>(),
    'Load Order Success': props<{ order: Order }>(),

    // eslint-disable-next-line perfectionist/sort-objects
    'Load Order SBP': emptyProps(),
    'Load Order SBP Failure': props<{ error: unknown }>(),
    'Load Order SBP Success': props<{ sbpData: SBPOrder }>(),

    'Load Order Yookassa': emptyProps(),
    'Load Order Yookassa Failure': props<{
      error: unknown;
    }>(),
    'Load Order Yookassa Success': props<{
      payload: Record<string, unknown>;
    }>(),

    'Load Shop Widget Data': emptyProps(),
    'Load Shop Widget Data Failure': props<{ error: unknown }>(),
    'Load Shop Widget Data Success': props<{ shopWidgetData: ShopWidget }>(),

    'Proceed To Payment': emptyProps(),
    'Proceed To Payment Failure': props<{ error: unknown }>(),
    'Proceed To Payment Success': props<{ order: Order }>(),

    'Release Promocode': emptyProps(),
    'Release Promocode Failure': props<{ error: unknown }>(),
    'Release Promocode Success': props<{ order: Order }>(),

    'Renew Order': emptyProps(),
    'Renew Order Failure': props<{ error: unknown }>(),
    'Renew Order Success': props<{ order: Order }>(),

    'Submit Invoice': emptyProps(),
    'Submit Invoice Failure': props<{ error: unknown }>(),
    'Submit Invoice Success': emptyProps(),

    'Update Data Before Proceed': props<{
      order: Partial<Order>;
      tickets: TicketWithFormModel[];
    }>(),
    'Update Data Before Proceed Failure': props<{ error: unknown }>(),
    'Update Data Before Proceed Success': props<{
      order: Order;
      tickets: TicketData[];
    }>(),

    'Update Order': props<{ order: Order }>(),
    'Update Order Failure': props<{ error: unknown }>(),
    'Update Order Success': props<{ order: Order }>(),
  },
  source: 'Order',
});

export const TicketActions = createActionGroup({
  events: {
    'Add Ticket': props<{ payload: OrderAddTicket }>(),
    'Add Ticket Failure': props<{ error: unknown }>(),
    'Add Ticket Success': props<{ ticket: TicketData }>(),

    'Clear Restore Ticket Data': emptyProps(),

    'Delete Ticket': props<{
      ticketId: string;
    }>(),
    'Delete Ticket Failure': props<{ error: unknown }>(),
    'Delete Ticket Success': props<{ ticketId: TicketData['id'] }>(),

    'Load Order Tickets': emptyProps(),
    'Load Order Tickets Failure': props<{ error: unknown }>(),
    'Load Order Tickets Success': props<{ tickets: TicketData[] }>(),

    'Restore Ticket': props<{ ticketRestore: TicketRestore }>(),
    'Restore Ticket Failure': props<{ error: unknown }>(),
    'Restore Ticket Success': props<{ ticketRestore: TicketRestore }>(),

    'Update Ticket': props<{
      data: TicketData;
      ticketId: string;
    }>(),
    'Update Ticket Failure': props<{ error: unknown }>(),
    'Update Ticket Success': props<{ ticket: TicketData }>(),
  },
  source: 'Ticket',
});
