/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShopDetail } from '../models/shop-detail';
import { ShopWidget } from '../models/shop-widget';
@Injectable({
  providedIn: 'root',
})
class ShopService extends __BaseService {
  static readonly shopListListPath = '/shop/list';
  static readonly shopListCreatePath = '/shop/list';
  static readonly shopReadPath = '/shop/{id}';
  static readonly shopWidgetListPath = '/shop/{id}/widget';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShopService.ShopListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  shopListListResponse(params: ShopService.ShopListListParams): __Observable<__StrictHttpResponse<Array<ShopDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shop/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShopDetail>>;
      })
    );
  }
  /**
   * @param params The `ShopService.ShopListListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  shopListList(params: ShopService.ShopListListParams): __Observable<Array<ShopDetail>> {
    return this.shopListListResponse(params).pipe(
      __map(_r => _r.body as Array<ShopDetail>)
    );
  }

  /**
   * @param data undefined
   */
  shopListCreateResponse(data: ShopDetail): __Observable<__StrictHttpResponse<ShopDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shop/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShopDetail>;
      })
    );
  }
  /**
   * @param data undefined
   */
  shopListCreate(data: ShopDetail): __Observable<ShopDetail> {
    return this.shopListCreateResponse(data).pipe(
      __map(_r => _r.body as ShopDetail)
    );
  }

  /**
   * @param id undefined
   */
  shopReadResponse(id: string): __Observable<__StrictHttpResponse<ShopDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shop/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShopDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  shopRead(id: string): __Observable<ShopDetail> {
    return this.shopReadResponse(id).pipe(
      __map(_r => _r.body as ShopDetail)
    );
  }

  /**
   * @param params The `ShopService.ShopWidgetListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `ym_client_id`: Yandex Metrika client id
   *
   * - `ga_client_id`: Google Analytics client id
   */
  shopWidgetListResponse(params: ShopService.ShopWidgetListParams): __Observable<__StrictHttpResponse<ShopWidget>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ymClientId != null) __params = __params.set('ym_client_id', params.ymClientId.toString());
    if (params.gaClientId != null) __params = __params.set('ga_client_id', params.gaClientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shop/${encodeURIComponent(String(params.id))}/widget`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShopWidget>;
      })
    );
  }
  /**
   * @param params The `ShopService.ShopWidgetListParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `ym_client_id`: Yandex Metrika client id
   *
   * - `ga_client_id`: Google Analytics client id
   */
  shopWidgetList(params: ShopService.ShopWidgetListParams): __Observable<ShopWidget> {
    return this.shopWidgetListResponse(params).pipe(
      __map(_r => _r.body as ShopWidget)
    );
  }
}

module ShopService {

  /**
   * Parameters for shopListList
   */
  export interface ShopListListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for shopWidgetList
   */
  export interface ShopWidgetListParams {
    id: string;

    /**
     * Yandex Metrika client id
     */
    ymClientId?: string;

    /**
     * Google Analytics client id
     */
    gaClientId?: string;
  }
}

export { ShopService }
