import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  Invoice,
  Order,
  SBPOrder,
  ShopWidget,
  TicketData,
  TicketRestore,
} from '@swagger/models';

import { OrderActions, TicketActions } from './order.actions';

interface State {
  addTicketLoading: boolean | undefined;
  deleteTicketLoading: boolean | undefined;
  error: unknown;
  invoice: Invoice | undefined;
  invoiceLoading: boolean | undefined;
  order: Order | undefined;
  orderError: unknown;
  orderLoading: boolean | undefined;
  orderPaymentData: Record<string, unknown> | undefined;
  proceedToPaymentLoading: boolean | undefined;
  promocodeError: unknown;
  promocodeLoading: boolean | undefined;
  restoreLoading: boolean | undefined;
  sbpData: SBPOrder | undefined;
  sbpLoading: boolean | undefined;
  shopWidgetData: ShopWidget | undefined;
  ticketLoading: boolean | undefined;
  ticketRestoreData: TicketRestore | undefined;
  tickets: TicketData[] | undefined;
}

const initialState: State = {
  addTicketLoading: undefined,
  deleteTicketLoading: undefined,
  error: undefined,
  invoice: undefined,
  invoiceLoading: undefined,
  order: undefined,
  orderError: undefined,
  orderLoading: undefined,
  orderPaymentData: undefined,
  proceedToPaymentLoading: undefined,
  promocodeError: undefined,
  promocodeLoading: undefined,
  restoreLoading: undefined,
  sbpData: undefined,
  sbpLoading: undefined,
  shopWidgetData: undefined,
  ticketLoading: undefined,
  ticketRestoreData: undefined,
  tickets: undefined,
};

const reducer = createReducer(
  initialState,
  on(
    OrderActions.createOrder,
    (state): State => ({
      ...state,
      order: undefined,
      orderError: undefined,
      orderLoading: true,
      tickets: undefined,
    }),
  ),
  on(
    OrderActions.loadOrder,
    OrderActions.updateOrder,
    OrderActions.renewOrder,
    (state): State => ({
      ...state,
      orderLoading: true,
    }),
  ),
  on(
    OrderActions.createOrderSuccess,
    OrderActions.loadOrderSuccess,
    OrderActions.updateOrderSuccess,
    OrderActions.renewOrderSuccess,
    (state, { order }): State => ({
      ...state,
      error: undefined,
      order,
      orderLoading: false,
    }),
  ),
  on(
    OrderActions.createOrderFailure,
    OrderActions.loadOrderFailure,
    OrderActions.updateOrderFailure,
    OrderActions.renewOrderFailure,
    (state, { error }): State => ({
      ...state,
      error,
      orderLoading: false,
    }),
  ),
  on(
    TicketActions.loadOrderTickets,
    (state): State => ({
      ...state,
      ticketLoading: true,
    }),
  ),
  on(
    TicketActions.loadOrderTicketsSuccess,
    (state, { tickets }): State => ({
      ...state,
      error: undefined,
      ticketLoading: false,
      tickets,
    }),
  ),
  on(
    TicketActions.loadOrderTicketsFailure,
    (state, { error }): State => ({
      ...state,
      error,
      ticketLoading: false,
    }),
  ),
  on(
    TicketActions.addTicket,
    (state): State => ({
      ...state,
      addTicketLoading: true,
    }),
  ),
  on(
    TicketActions.addTicketSuccess,
    (state, { ticket }): State => ({
      ...state,
      addTicketLoading: false,
      error: undefined,
      tickets: [...(state.tickets ?? []), ticket],
    }),
  ),
  on(
    TicketActions.addTicketFailure,
    (state, { error }): State => ({
      ...state,
      addTicketLoading: false,
      error,
    }),
  ),
  on(
    TicketActions.deleteTicket,
    (state): State => ({
      ...state,
      deleteTicketLoading: true,
    }),
  ),
  on(
    TicketActions.deleteTicketSuccess,
    (state, { ticketId }): State => ({
      ...state,
      deleteTicketLoading: false,
      tickets: state.tickets?.filter((ticket) => ticket.id !== ticketId),
    }),
  ),
  on(
    TicketActions.deleteTicketFailure,
    (state): State => ({
      ...state,
      deleteTicketLoading: false,
    }),
  ),
  on(
    OrderActions.applyPromocode,
    OrderActions.releasePromocode,
    (state): State => ({
      ...state,
      promocodeLoading: true,
    }),
  ),
  on(
    OrderActions.applyPromocodeSuccess,
    OrderActions.releasePromocodeSuccess,
    (state, { order }): State => ({
      ...state,
      order,
      promocodeError: undefined,
      promocodeLoading: false,
    }),
  ),
  on(
    OrderActions.applyPromocodeFailure,
    OrderActions.releasePromocodeFailure,
    (state, { error }): State => ({
      ...state,
      promocodeError: error,
      promocodeLoading: false,
    }),
  ),
  on(
    OrderActions.loadOrderSBP,
    (state): State => ({
      ...state,
      sbpLoading: true,
    }),
  ),
  on(
    OrderActions.loadOrderSBPSuccess,
    (state, { sbpData }): State => ({
      ...state,
      sbpData,
      sbpLoading: false,
    }),
  ),
  on(
    OrderActions.loadOrderSBPFailure,
    (state): State => ({
      ...state,
      sbpLoading: false,
    }),
  ),
  on(
    OrderActions.loadShopWidgetDataSuccess,
    (state, { shopWidgetData }): State => ({
      ...state,
      shopWidgetData,
    }),
  ),
  on(
    OrderActions.loadOrderYookassaSuccess,
    (state, { payload }): State => ({
      ...state,
      orderPaymentData: payload,
    }),
  ),
  on(
    OrderActions.loadInvoiceSuccess,
    (state, { invoice }): State => ({
      ...state,
      invoice,
      orderLoading: false,
    }),
  ),
  on(
    TicketActions.updateTicketSuccess,
    (state, { ticket }): State => ({
      ...state,
      tickets: state.tickets?.map((existingTicket) =>
        existingTicket.id === ticket.id ? ticket : existingTicket,
      ),
    }),
  ),
  on(
    TicketActions.restoreTicket,
    (state): State => ({
      ...state,
      restoreLoading: true,
    }),
  ),
  on(
    TicketActions.restoreTicketSuccess,
    (state, { ticketRestore }): State => ({
      ...state,
      restoreLoading: false,
      ticketRestoreData: ticketRestore,
    }),
  ),
  on(
    TicketActions.restoreTicketFailure,
    (state): State => ({
      ...state,
      restoreLoading: false,
    }),
  ),
  on(
    TicketActions.clearRestoreTicketData,
    (state): State => ({
      ...state,
      ticketRestoreData: undefined,
    }),
  ),
  on(
    OrderActions.updateDataBeforeProceed,
    OrderActions.proceedToPayment,
    (state): State => ({
      ...state,
      orderError: undefined,
      proceedToPaymentLoading: true,
    }),
  ),
  on(
    OrderActions.updateDataBeforeProceedSuccess,
    (state, { order, tickets }): State => ({
      ...state,
      order,
      tickets,
    }),
  ),
  on(
    OrderActions.updateDataBeforeProceedFailure,
    OrderActions.proceedToPaymentSuccess,
    (state): State => ({
      ...state,
      proceedToPaymentLoading: false,
    }),
  ),
  on(
    OrderActions.proceedToPaymentFailure,
    (state, { error }): State => ({
      ...state,
      orderError: error,
      proceedToPaymentLoading: false,
    }),
  ),
  on(
    OrderActions.createInvoice,
    (state): State => ({
      ...state,
      invoiceLoading: true,
    }),
  ),
  on(
    OrderActions.createInvoiceSuccess,
    (state, { invoice }): State => ({
      ...state,
      invoice,
      invoiceLoading: false,
    }),
  ),
  on(
    OrderActions.createInvoiceFailure,
    (state, { error }): State => ({
      ...state,
      error,
      invoiceLoading: false,
    }),
  ),
);

export const orderFeature = createFeature({
  extraSelectors: ({ selectOrder, selectShopWidgetData }) => ({
    selectAppliedPromocode: createSelector(
      selectOrder,
      (order) => order?.promocode,
    ),
    selectShopData: createSelector(selectShopWidgetData, (data) => data?.shop),
  }),
  name: 'order',
  reducer,
});
