import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import {
  OrderStatus,
  PIREXPO_CONFIG,
  PirexpoConfig,
  ROUTE_PARAMS_TOKENS,
  ROUTE_TOKENS,
} from '@pirexpo/shared/app-config';
import { TicketData } from '@swagger/models';
import { OrderService } from '@swagger/services/order.service';
import { SbpService } from '@swagger/services/sbp.service';
import { ShopService } from '@swagger/services/shop.service';
import { TicketService } from '@swagger/services/ticket.service';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { OrderActions, TicketActions } from './order.actions';

@Injectable()
export class OrderEffects {
  private readonly ROUTE_PARAMS_TOKENS = ROUTE_PARAMS_TOKENS;
  private readonly ROUTE_TOKENS = ROUTE_TOKENS;

  addTicket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.addTicket),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([{ payload }, id]) =>
        this.orderService
          .orderAddTicketCreate({
            data: { ticket_type_id: payload.ticket_type_id },
            id: <string>id,
          })
          .pipe(
            map((ticket) => TicketActions.addTicketSuccess({ ticket })),
            catchError((error: unknown) =>
              of(
                TicketActions.addTicketFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  applyPromocode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.applyPromocode),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([{ payload }, id]) =>
        this.orderService
          .orderApplyPromocodeCreate({ data: payload, id: String(id) })
          .pipe(
            map((order) => OrderActions.applyPromocodeSuccess({ order })),
            catchError((error: unknown) =>
              of(
                OrderActions.applyPromocodeFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  createInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.createInvoice),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([{ payload }, id]) =>
        this.orderService
          .orderInvoiceCreate({ data: payload, id: String(id) })
          .pipe(
            map((invoice) => OrderActions.createInvoiceSuccess({ invoice })),
            catchError((error: unknown) =>
              of(
                OrderActions.createInvoiceFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  createOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.createOrder),
      exhaustMap(({ ticketTypeIds }) =>
        this.orderService
          .orderCreateCreate({ ticket_type_ids: <number[]>ticketTypeIds })
          .pipe(
            map((order) => OrderActions.createOrderSuccess({ order })),
            catchError((error: unknown) =>
              of(
                OrderActions.createOrderFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  deleteTicket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.deleteTicket),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([{ ticketId }, id]) =>
        this.orderService
          .orderDeleteTicketDelete({
            id: <string>id,
            ticketId,
          })
          .pipe(
            map(() =>
              TicketActions.deleteTicketSuccess({
                ticketId: Number(ticketId),
              }),
            ),
            catchError((error: unknown) =>
              of(
                TicketActions.deleteTicketFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  handleCompletedOrder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderActions.loadOrderSuccess),
        filter(({ order }) => order.status === OrderStatus.Completed),
        tap(({ order }) => {
          this.router.navigate([
            `/${this.ROUTE_TOKENS.TICKET.ORDER_COMPLETE}/${order.id}`,
          ]);
        }),
      );
    },
    { dispatch: false },
  );

  handleOrderStatusAfterProceed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderActions.proceedToPaymentSuccess),
        tap(({ order }) => {
          const status = order.status;

          if (status === OrderStatus.InProgress) {
            this.router.navigate([
              `/${this.ROUTE_TOKENS.TICKET.PAYMENT}`,
              order.id,
            ]);
          } else if (status === OrderStatus.Completed) {
            this.router.navigate([
              `/${this.ROUTE_TOKENS.TICKET.ORDER_COMPLETE}`,
              order.id,
            ]);
          }
        }),
      );
    },
    { dispatch: false },
  );

  loadInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadInvoice),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderInvoiceList(String(id)).pipe(
          map((invoice) => OrderActions.loadInvoiceSuccess({ invoice })),
          catchError((error: unknown) =>
            of(
              OrderActions.loadInvoiceFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadOrder),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderRead(Number(id)).pipe(
          map((order) => OrderActions.loadOrderSuccess({ order })),
          catchError((error: unknown) =>
            of(
              OrderActions.loadOrderFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadOrderSBP$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadOrderSBP),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.sbpService.sbpOrderRead(<string>id).pipe(
          map((sbpData) => OrderActions.loadOrderSBPSuccess({ sbpData })),
          catchError((error: unknown) =>
            of(
              OrderActions.loadOrderSBPFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadOrderTickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.loadOrderTickets),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderTicketListList(<string>id).pipe(
          map((tickets) => TicketActions.loadOrderTicketsSuccess({ tickets })),
          catchError((error: unknown) =>
            of(
              TicketActions.loadOrderTicketsFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadOrderYookassa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadOrderYookassa),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService
          .orderYookassaCreatePaymentList({ id: <string>id, test: false })
          .pipe(
            map((payload: unknown) =>
              OrderActions.loadOrderYookassaSuccess({
                payload: <Record<string, unknown>>payload,
              }),
            ),
            catchError((error: unknown) =>
              of(
                OrderActions.loadOrderYookassaFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  loadShopWidgetData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadShopWidgetData),
      exhaustMap(() =>
        this.shopWidgetService
          .shopWidgetList({ id: String(this.appConfig.SHOP_ID) })
          .pipe(
            map((shopWidgetData) =>
              OrderActions.loadShopWidgetDataSuccess({ shopWidgetData }),
            ),
            catchError((error: unknown) =>
              of(
                OrderActions.loadShopWidgetDataFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  moveToProceedAfterSuccessDataUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateDataBeforeProceedSuccess),
      exhaustMap(() => of(OrderActions.proceedToPayment())),
    );
  });

  navigateOnOrderRenew$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderActions.renewOrderSuccess),
        tap(({ order }) => {
          this.dialog.closeAll();

          this.router.navigate([
            `/${this.ROUTE_TOKENS.TICKET.ORDER}`,
            order.id,
          ]);
        }),
      );
    },
    { dispatch: false },
  );

  navigateToSuccessInvoice$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderActions.createInvoiceSuccess),
        tap(({ invoice }) => {
          this.router.navigate([
            `/${this.ROUTE_TOKENS.TICKET.INVOICE_FOR_PAYMENT}`,
            invoice.order,
          ]);
        }),
      );
    },
    { dispatch: false },
  );

  navigateToTickets$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderActions.createOrderSuccess),
        tap(({ order }) => {
          this.router.navigate([
            `/${this.ROUTE_TOKENS.TICKET.ORDER}/${order.id}`,
          ]);
        }),
      );
    },
    { dispatch: false },
  );

  proceedToPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.proceedToPayment),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderProceedToPaymentCreate(String(id)).pipe(
          map((order) => OrderActions.proceedToPaymentSuccess({ order })),
          catchError((error: unknown) =>
            of(
              OrderActions.proceedToPaymentFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  releasePromocode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.releasePromocode),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderReleasePromocodeCreate(String(id)).pipe(
          map((order) => OrderActions.releasePromocodeSuccess({ order })),
          catchError((error: unknown) =>
            of(
              OrderActions.releasePromocodeFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  reloadOrderOnTicketAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.addTicketSuccess, TicketActions.deleteTicketSuccess),
      exhaustMap(() => of(OrderActions.loadOrder())),
    );
  });

  renewOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.renewOrder),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderRenewCreate(<string>id).pipe(
          map((order) => OrderActions.renewOrderSuccess({ order })),
          catchError((error: unknown) =>
            of(
              OrderActions.renewOrderFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  restoreTickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.restoreTicket),
      exhaustMap(({ ticketRestore }) =>
        this.ticketService.ticketRestoreCreate(ticketRestore).pipe(
          map((ticketRestoreResponse) =>
            TicketActions.restoreTicketSuccess({
              ticketRestore: ticketRestoreResponse,
            }),
          ),
          catchError((error: unknown) =>
            of(
              TicketActions.restoreTicketFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  submitInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.submitInvoice),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([, id]) =>
        this.orderService.orderInvoiceSubmitList(String(id)).pipe(
          map(() => OrderActions.submitInvoiceSuccess()),
          catchError((error: unknown) =>
            of(
              OrderActions.submitInvoiceFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        ),
      ),
    );
  });

  updateDataBeforeProceed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateDataBeforeProceed),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      switchMap(([{ order, tickets }, id]) => {
        const requests = [];
        const orderUpdateRequest = this.orderService.orderPartialUpdate({
          data: order,
          id: Number(id),
        });

        requests.push(orderUpdateRequest);

        tickets.forEach((ticket) => {
          const ticketUpdateRequest =
            this.orderService.orderUpdateTicketPartialUpdate({
              data: {
                formly: {
                  form_data: ticket['model'],
                },
              },
              id: <string>id,
              ticketId: String(ticket.ticket.id),
            });
          requests.push(ticketUpdateRequest);
        });

        return forkJoin([...requests]).pipe(
          map(([updatedOrder, ...updatedtickets]) =>
            OrderActions.updateDataBeforeProceedSuccess({
              order: updatedOrder,
              tickets: <TicketData[]>updatedtickets,
            }),
          ),
          catchError((error: unknown) =>
            of(
              OrderActions.updateDataBeforeProceedFailure({
                error: (<HttpErrorResponse>error)?.error,
              }),
            ),
          ),
        );
      }),
    );
  });

  updateOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateOrder),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      exhaustMap(([{ order }, id]) =>
        this.orderService
          .orderPartialUpdate({ data: order, id: Number(id) })
          .pipe(
            map((updatedOrder) =>
              OrderActions.updateOrderSuccess({ order: updatedOrder }),
            ),
            catchError((error: unknown) =>
              of(
                OrderActions.updateOrderFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  updateTicket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketActions.updateTicket),
      concatLatestFrom(() =>
        this.store.select(
          getRouterSelectors().selectRouteParam(
            this.ROUTE_PARAMS_TOKENS.ORDER_ID,
          ),
        ),
      ),
      concatMap(([{ data, ticketId }, id]) =>
        this.orderService
          .orderUpdateTicketPartialUpdate({
            data,
            id: <string>id,
            ticketId,
          })
          .pipe(
            map((ticket) => TicketActions.updateTicketSuccess({ ticket })),
            catchError((error: unknown) =>
              of(
                TicketActions.updateTicketFailure({
                  error: (<HttpErrorResponse>error)?.error,
                }),
              ),
            ),
          ),
      ),
    );
  });

  constructor(
    @Inject(PIREXPO_CONFIG) private readonly appConfig: PirexpoConfig,
    private store: Store,
    private actions$: Actions,
    private orderService: OrderService,
    private ticketService: TicketService,
    private sbpService: SbpService,
    private shopWidgetService: ShopService,
    private router: Router,
    private dialog: MatDialog,
  ) {}
}
